<template>
  <UForm
    :schema="schema"
    :state="state"
    class="mt-4 flex w-full space-x-2"
    @submit="onSubmit"
  >
    <UFormField label="Email" name="email" class="w-full">
      <UInput v-model="state.email" class="w-full" />
    </UFormField>

    <div>
      <UButton
        type="submit"
        loading-auto
        :disabled="!state.email"
        class="mt-6 shrink-0 grow-0"
        @click="
          proxy.gtag('event', 'newsletter_signup_form_submitted');
          $clientPosthog?.capture('newsletter_signup_form_submitted');
        "
      >
        Submit
      </UButton>
    </div>
  </UForm>
</template>

<script setup lang="ts">
import { z } from 'zod';
import type { FormSubmitEvent } from '#ui/types';

const schema = z.object({
  email: z.string().email('Invalid email'),
});

type Schema = z.output<typeof schema>;

const state = reactive<Partial<Schema>>({
  email: undefined,
});

const toast = useToast();

async function onSubmit(event: FormSubmitEvent<Schema>) {
  try {
    const { data, error } = await useFetch('/api/marketing/subscribe', {
      method: 'POST',
      body: {
        emailAddress: event.data.email,
        blackListed: false,
        customFields: {},
      },
    });

    // Check if there's an error object returned
    if (error.value) {
      toast.add({
        title: 'Error',
        icon: 'i-lucide-circle-alert',
        description: error.value.message,
        color: 'error',
      });
      throw new Error(error.value.message || 'Subscription failed');
    }

    state.email = undefined;

    // If no error, handle the successful response
    if (data.value) {
      toast.add({
        title: 'Success',
        icon: 'i-lucide-circle-check',
        description: 'You have been subscribed to our newsletter.',
        color: 'success',
      });
      state.email = undefined;
    }
  } catch (error: any) {
    // Handle errors here
    toast.add({
      title: 'Error',
      icon: 'i-lucide-circle-alert',
      description: error.message || 'An error occurred while subscribing.',
      color: 'error',
    });
  }
}
</script>
